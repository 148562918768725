<template>
    <div class="question" :class="answers.length > 2 ? 'more-than-two' : ''">
        <h3 class="h3">{{ question }}</h3>
        <ul class="answers-list" :class="`len-${answers.length}`" v-if="answers.length">
            <li v-for="(item, index) in answers" :key="index" class="list-item">
                <label class="control-checkbox" @click="ifDoubleClick">
                    <input type="radio" :value="item.value" v-model="model" />
                    <span class="control-button">{{ item.title }}</span>
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        question: {
            type: String,
            default: '',
        },
        answers: {
            type: Array,
            default: () => [],
        },
        value: [Boolean, String, Object, Number],
    },
    data() {
        return {
            click: null,
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        ifDoubleClick() {
            if (this.click <= 2) {
                this.click++;
            } else if (this.click >= 3) {
                this.click--;
                this.$emit('input', '');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.question {
    .answers-list {
        display: flex;
    }

    .h3 {
        margin-bottom: 16px;
    }

    .list-item {
        width: 100%;

        &:not(:last-child) {
            margin-right: 30px;
        }
    }

    .control-checkbox {
        cursor: pointer;

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;

            &:checked {
                ~ .control-button {
                    color: black;
                    background: #f5eee8;
                    border-color: #f5eee8;
                }
            }
        }

        .control-button {
            display: block;
            padding: 10px 10.5px;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.03em;
            text-align: center;
            color: #646464;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            pointer-events: none;
            transition: 0.25s linear;

            @media (max-width: 390px) {
                padding: 10px 7.5px;
            }
        }
    }

    &.more-than-two {
        .list-item {
            width: 45%;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 350px) {
    .answers-list {
        &.len-4 {
            flex-wrap: wrap;
            margin-bottom: -15px;
            .list-item {
                margin-bottom: 15px;
            }
        }
    }
}
</style>
