export default {
	data() {
		return {
			sleepQuestions: [
				{
					question: this.$t('sleep.awake'),
					model: "awake",
					answers: [
						{ title: this.$t('common.yes'), value: 1 },
						{ title: this.$t('common.no'), value: 0 },
					],
				},
				{
					question: this.$t('sleep.nightmares'),
					model: "nightmares",
					answers: [
						{ title: this.$t('common.yes'), value: 1 },
						{ title: this.$t('common.no'), value: 0 },
					],
				},
				{
					question: this.$t('sleep.deep_dream'),
					model: "deep_sleep",
					answers: [
						{ title: this.$t('common.yes'), value: 1 },
						{ title: this.$t('common.no'), value: 0 },
					],
				},
			]
		}
	}

}
