<template>
    <van-dialog v-model="isShow" :show-confirm-button="false" class="mood-modal" :overlay="false">
        <h3 class="h3">{{ $t('mood.entry_added_to_libary') }}</h3>
        <div class="white-block">
            <h4 class="block-title">{{ $t('mood.new_entry') }}</h4>
            <span class="block-date">{{ form.date }}</span>
            <div class="row">
                <div class="col">
                    <div class="inner-block">
                        <h4 class="h4">{{ $t('sleep.title') }}</h4>
                        <h5 class="h5">{{ form.sleep_time }} {{ $t('date.hours') }}</h5>
                        <div class="icon-holder">
                            <img
                                src="@/assets/images/s-green.svg"
                                alt=""
                                v-if="this.form.sleep_time >= 7 && this.form.sleep_time <= 10" />
                            <img
                                src="@/assets/images/s-light.svg"
                                alt=""
                                v-if="this.form.sleep_time >= 11" />
                            <img
                                src="@/assets/images/s-dark.svg"
                                alt=""
                                v-if="this.form.sleep_time >= 0 && this.form.sleep_time <= 6" />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="inner-block">
                        <h4 class="h4">{{ $t('mood.title') }}</h4>
                        <h5 v-if="mood && mood.title" class="h5" :style="{ color: mood.color }">
                            {{ mood.title }}
                        </h5>
                        <div v-if="mood && mood.icon" class="icon-holder">
                            <img :src="require(`@/assets/images/${mood.icon}`)" alt="..." />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-btn-holder">
            <!-- <van-button type="primary" class="button-black" @click="handleClose">{{ $t("buttons.open_history_status") }}</van-button> -->
            <van-button type="primary" class="button-black" @click="handleClose">
                {{ $t('buttons.close_window') }}
            </van-button>
        </div>
    </van-dialog>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
            default: '',
        },
        mood: {
            type: Object,
            default: () => {},
        },
        form: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isShow: false,
        };
    },
    methods: {
        handleShow() {
            this.isShow = true;
        },
        handleClose() {
            this.isShow = false;
            let tg = window.Telegram.WebApp;
            tg.close();
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.mood-modal {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5eee8;

    .h3 {
        max-width: 265px;
        margin: auto auto 23px;
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.04em;
        color: #000000;
    }

    .white-block {
        margin-bottom: 89px;
        padding: 16px 16px 30px 15px;
        background: #ffffff;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);
        border-radius: 16px;

        .block-title {
            margin-bottom: 2px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #897a6c;
            text-align: center;
        }

        .block-date {
            display: block;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #000000;
        }

        .row {
            display: flex;
            margin-left: -10px;
            margin-right: -10px;

            .col {
                width: 49%;
                justify-content: space-between;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .inner-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 10px 30px 10px;
            height: 100%;
            background: #ffffff;
            text-align: center;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.17);
            border-radius: 16px;

            .h4 {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.02em;
                color: #000000;
            }

            .h5 {
                margin-bottom: 22px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }

            .icon-holder {
                width: 70px;
                height: 70px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .modal-btn-holder {
        .van-button {
            &:first-child {
                margin-bottom: 14px;
            }
        }
    }
}
</style>
