var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"h1"},[_vm._v(_vm._s(_vm.$t("sleep.title")))]),_c('WhiteHolder',{staticClass:"sleep-slider-holder",class:{
        'value-null': _vm.form.sleep_time === 0,
        'good-state': _vm.form.sleep_time >= 6 && _vm.form.sleep_time <= 11,
        error: _vm.v$.form.sleep_time.$error,
        'fix-btn': _vm.form.sleep_time <= 2,
      }},[_c('h3',{staticClass:"h3"},[_vm._v(_vm._s(_vm.$t("sleep.sleep_time")))]),_c('vue-slider',{attrs:{"height":30,"min":0,"max":20,"tooltip":"always","contained":true},model:{value:(_vm.form.sleep_time),callback:function ($$v) {_vm.$set(_vm.form, "sleep_time", $$v)},expression:"form.sleep_time"}})],1),_c('WhiteHolder',{staticClass:"sleep-slider-holder",class:{
        'value-null': _vm.form.sleep_quality === 0,
        'good-state': _vm.form.sleep_quality >= 7,
        'fix-btn': _vm.form.sleep_quality <= 1,
      }},[_c('h3',{staticClass:"h3"},[_vm._v(_vm._s(_vm.$t("sleep.sleep_quality")))]),_c('vue-slider',{attrs:{"height":30,"min":0,"max":10,"tooltip":"always","contained":true},model:{value:(_vm.form.sleep_quality),callback:function ($$v) {_vm.$set(_vm.form, "sleep_quality", $$v)},expression:"form.sleep_quality"}})],1),_vm._l((_vm.sleepQuestions),function(item,index){return _c('WhiteHolder',{key:index},[_c('Question',{attrs:{"question":item.question,"answers":item.answers,"error":_vm.v$.form[item.error] && _vm.v$.form[item.error].$error},model:{value:(_vm.form[item.model]),callback:function ($$v) {_vm.$set(_vm.form, item.model, $$v)},expression:"form[item.model]"}})],1)})],2),_c('div',{staticClass:"page-footer"},[_c('van-button',{staticClass:"button-white button-right-icon",attrs:{"type":"primary","icon-position":"right"},on:{"click":_vm.submitForm},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/images/light-arrow-right.svg"),"alt":"..."}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t("common.btn_sleep"))+" ")])],1),_c('van-notify',{attrs:{"type":"danger"},model:{value:(_vm.showFormError),callback:function ($$v) {_vm.showFormError=$$v},expression:"showFormError"}},[_c('span',[_vm._v(_vm._s(_vm.$t("dialog.error_data_1")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }